@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap');

:root {
  --background: #0f0f13;
  --card-bg: rgba(38, 38, 45, 0.7);
  --text-primary: #ffffff;
  --text-secondary: #b0b0b0;
  --accent-orange: #ff8533;
  --gradient-flame: linear-gradient(135deg, #ff4d4d, #ff8533, #ffcc00);
  --border-radius: 8px;
  --transition: all 0.3s ease;
}

body {
  background: var(--background);
  color: var(--text-primary);
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 20px;
  min-height: 100vh;
  position: relative;
}

body::before {
  content: '';
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 50vh;
  background-image: url('https://www.fghost.fi/fghost-logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  opacity: 0.1;
  z-index: -1;
  pointer-events: none;
}

/* Main container */
.migratoor-container {
  max-width: 600px;
  margin: 40px auto;
  padding: 30px;
  background: var(--card-bg);
  border-radius: var(--border-radius);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Title */
h1 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  background: var(--gradient-flame);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 700;
}

/* Buttons */
button {
  background: var(--gradient-flame);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: var(--border-radius);
  cursor: pointer;
  font-weight: 600;
  transition: var(--transition);
}

button:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 15px rgba(255, 133, 51, 0.4);
}

/* Balance display */
.balance {
  font-size: 1.2rem;
  margin: 20px 0;
  color: var(--text-primary);
}

/* Input field */
input {
  width: 100%;
  padding: 12px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: var(--border-radius);
  color: var(--text-primary);
  font-size: 1rem;
  margin-bottom: 0;
  box-sizing: border-box;
}

input:focus {
  outline: none;
  border-color: var(--accent-orange);
  box-shadow: 0 0 10px rgba(255, 133, 51, 0.2);
}

/* Stats display */
.stats {
  font-size: 1.1rem;
  margin-top: 20px;
  color: var(--text-secondary);
}

/* Button group */
.button-group {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

/* Wallet connection */
.wallet-button {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.wallet-button:hover {
  background: rgba(255, 255, 255, 0.15);
}

.app-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.wallet-info {
  background: var(--card-bg);
  padding: 10px 20px;
  border-radius: var(--border-radius);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.wallet-address {
  font-family: monospace;
  font-size: 1.1rem;
  color: var(--accent-orange);
}

.card-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.balance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: var(--border-radius);
}

.balance-amount {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--accent-orange);
}

.input-group {
  position: relative;
  width: 100%;
}

.input-group input {
  font-size: 1.2rem;
  width: 100%;
  margin: 0;
}

.action-button {
  width: 100%;
  padding: 16px;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  box-sizing: border-box;
}

.action-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.status-message {
  display: flex;
  gap: 12px;
  align-items: flex-start;
  padding: 16px;
  background: rgba(255, 133, 51, 0.1);
  border-radius: var(--border-radius);
  border: 1px solid rgba(255, 133, 51, 0.2);
}

.status-icon {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  color: var(--accent-orange);
}

.status-message p {
  margin: 0;
  font-size: 0.95rem;
  line-height: 1.5;
}

.stats {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 24px;
}

.stat-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.stat-row:last-child {
  margin-bottom: 0;
}

.stat-row span:last-child {
  color: var(--accent-orange);
  font-weight: 600;
}

@media (max-width: 640px) {
  .header {
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }

  h1 {
    margin-bottom: 0;
  }
}
